.allUniversitiesContainer {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
}

.heading {
  color: #2589ad;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-block: 2rem;
}

.universityCard {
  display: flex;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  gap: 1rem;
  padding: 1rem;
}

.universityCard:nth-child(odd) {
  flex-direction: row-reverse;
}

.uniImageContainer {
  position: relative;
  width: 65%;
}

.universityImage {
  object-fit: cover;
  position: relative;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  z-index: 2;
}

.badge,
.projectsBadge,
.infoBadge {
  background: #8cf059;
  color: #0e1726;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
}

.badge {
  position: absolute;
  top: 1rem;
  left: 0;
}

.projectsBadge {
  position: absolute;
  bottom: 6rem;
  right: 0px;
}

.infoBadge {
  position: absolute;
  bottom: 0.3rem;
  left: 3rem;
}

.universityContent {
  padding: 1.5rem;
  background-color: #fff;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
}

.universityContent > h2 {
  font-size: 1rem;
  font-weight: 700;
  color: #2589ad;
}

.universityLogo {
  width: auto;
  height: 40px;
  margin-bottom: 1rem;
}

.universityContent p {
  color: #000;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

.viewMoreBtn {
  background: #40a353;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.65rem;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.viewMoreBtn > img {
  margin-left: 0.4rem;
}

/* Responsive Styles */
@media (max-width: 64rem) {
  .allUniversitiesContainer {
    width: 90%;
  }

  .universityContent {
    padding: 0.75rem;
  }
}

@media (max-width: 55rem) {
  .universityContent {
    width: 45%;
  }

  .universityImage {
    width: 100%;
  }

  .badge,
  .projectsBadge,
  .infoBadge {
    position: static;
    width: 45%;
    font-size: 0.8rem;
    padding: 0.65rem;
  }
}

@media (max-width: 42rem) {
  .universityCard {
    flex-direction: column;
  }

  .universityCard:nth-child(odd) {
    flex-direction: column;
  }

  .universityContent {
    width: 85%;
  }
  .uniImageContainer {
    width: 100% !important;
  }
}
