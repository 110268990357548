.navbarContainer {
    overflow: hidden;
    background-color: #FFFFFF;
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: 0px 1.9px 3.8px 0px #00000024;
    z-index: 999999999;
}

.navbarContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.4rem 3.5rem;
}

.navbarNavigation a {
    text-decoration: none !important;
}

.navbarNavigation {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.navbarNavigation h2 {
    color: #000000;
    font-family: 'Be Vietnam Pro';
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
}



.contactBtn {
    border-radius: 4.4px;
    background-color: #40A353;
    color: #FFFFFF;
    font-size: 1.1rem;
    font-family: 'Be Vietnam Pro';
    font-weight: 700;
    border: none;
    padding: 0.65rem 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}



.mobileIcon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.activeLink h2 {
    background-color: #40A353;
    /* Active background color */
    color: #FFFFFF;
    /* Text color for the active link */
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.buttonLink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 2px solid #40A353;
    /* Green border */
    border-radius: 4px;
    color: #40A353;
    /* Green text */
    font-size: 1rem;
    font-family: 'Be Vietnam Pro';
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    /* Removes underline */
}

/* Add hover effect */
.buttonLink:hover {
    background-color: #FFFFFF;
    color: #40A353 !important;
}

/* Active link styling */
.activeLink {
    background-color: #40A353;
    color: #FFFFFF !important;
}

.logoutButton {
    background: none;
    border: none;
    color: #40A353;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Be Vietnam Pro';
    font-weight: 400;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.logoutButton:hover {
    color: #000;
}

@media screen and (max-width: 48rem) {
    .navbarContent {
        margin: 0.4rem 1rem;
    }

    .navbarNavigation {
        gap: 1rem;
    }
}

/* Styles for the mobile menu */
@media screen and (max-width: 37.5rem) {
    .navbarContent {
        margin: 0.4rem 1.5rem;
        justify-content: space-between;
    }

    .navbarNavigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 50vh;
        background-color: #FFFFFF;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 9999;
        overflow-y: auto;
    }

    .navbarNavigation.mobileMenu {
        transform: translateY(0);
    }

    /* Show the hamburger icon */
    .mobileIcon {
        display: block;
        position: relative;
        z-index: 10000;
    }

    /* Disable scrolling on the body when the mobile menu is open */
    body.mobileMenu-open {
        overflow: hidden;
    }

    .navbarContainer::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
        z-index: 9998;
    }

    /* Show the backdrop when the menu is open */
    .navbarNavigation.mobileMenu+.navbarContainer::after {
        opacity: 1;
        visibility: visible;
    }
}