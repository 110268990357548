.dashboardContainer {
  margin: 0;
  font-size: 1rem;
  background: #FFFFFF;
  display: grid;
  grid-template-columns: 14rem 1fr;
  gap: 2rem;
}

.tabsContainer {
  position: relative;
  top: 0;
  min-height: 125svh;
  background: linear-gradient(180deg, #a3e5ff, #a8ff78);
}

.tabsNavigation {
  margin: 0;
  padding: 0;
  list-style: none;

  display: grid;
  gap: 2rem;
  padding-block: 5rem;
  padding-left: 1.3rem;
}

.navigateContainer {
  color: white !important;
  font-family: Tajawal;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.375rem;
  padding: 0.7rem;
  border-radius: 100vw 0 0 100vw;
  text-decoration: none;
}

a.active {
  background: #FFFFFF;
  position: relative;
  text-decoration: none;
  color: black !important;
}

/* a.active::before,
a.active::after {
  --border-radius: 1.5rem;

  content: '';
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  right: 0;
  background: #F3F3F3;
} */
.logout {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 50px;
  left: 50px;
  color: white;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
}

a.active::before {
  top: calc(var(--border-radius)* -1);
  border-radius: 0 0 100vw 0;
  box-shadow: 10px 10px 0 10px #FFFFFF;
}

a.active::after {
  bottom: calc(var(--border-radius)* -1);
  border-radius: 0 100vw 0 0;
  box-shadow: 10px -10px 0 10px #FFFFFF;
}

.navItem {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding-left: 1rem;
}

.navItem img {
  width: 2.1rem;
}

.navItem p {
  padding: .8rem;
}

.menuButton {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  /* Place it above other elements */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .dashboardContainer {
    grid-template-columns: 1fr;
  }

  /* Sidebar is hidden by default on mobile */
  .tabsContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    transform: translateX(-100%);
    z-index: 999;
  }

  /* Show sidebar when toggled */
  .showSidebar {
    transform: translateX(0);
  }

  /* Display menu button on mobile */
  .menuButton {
    display: block;
  }

  .mainContent {
    margin-top: 60px;
    padding: 10px;
  }
}

/* Student Table Styles */
.studentTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-x: auto;
}

.studentTable th,
.studentTable td {
  padding: 10px;
  text-align: left;
  font-size: 0.9rem;
}

@media (max-width: 480px) {
  .statsCard h2 {
    font-size: 1.5rem;
  }

  .statsCard p {
    font-size: 0.9rem;
  }

  .studentTable th,
  .studentTable td {
    padding: 8px;
    font-size: 0.8rem;
  }
}