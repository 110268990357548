/* .appContainer {
    min-height: 74vh !important;
} */

.homeContainer {
    flex-grow: 1;
}

.subFooter {
    flex-shrink: 0;
}