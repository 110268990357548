/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gray-44: #444444;
  --gray-55: #555555;
  --gray-c0: #c0c0c0;
  --gray-d9: #d9d9d9;
  --gray-ea: #eaeaea;
  --white-f5: #f5f5f5;
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  font-size: 16px;
}
@media (max-width: 1280px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  :root {
    font-size: 11px;
  }
}

body * {
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif;
  color: black;
}
body {
  overflow: visible !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.custom-container {
  padding-inline: 10%;
  position: relative;
  margin: auto;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
}

input,
textarea {
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}

a,
button {
  cursor: pointer;
}

button {
  all: unset;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 0;
}

h2 {
  font-size: 1.25rem;
  font-weight: 400;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.btn-primary {
  @apply transition duration-300 bg-primary text-[1rem] text-white text-center rounded-md cursor-pointer py-[1rem] px-[1.5rem] font-semibold;
}
