.dashboardContainer {
    display: flex;
    /* height: 100vh; */
    /* height: 100vh; */
    /* min-height: 100vh; */
    font-family: Arial, sans-serif;
}

.sidebar {
    width: 250px;
    background: linear-gradient(180deg, #00d48c, #1fbced);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navMenu ul {
    list-style: none;
    padding: 0;
}

.navItem {
    color: #fff;
    padding: 15px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.logoutContainer {
    text-align: center;
}

.logoutButton {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.mainContent {
    flex-grow: 1;
    padding: 20px;
}

.cardStatePayment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.statsCard {
    background-color: #fff;
    padding: 1.3rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 25%;
}

.statsCard h2 {
    font-size: 2.5rem;
    padding-bottom: 0.3rem;
    color: black;
}

.statsCard p {
    font-size: 1.2rem;
    color: #666;
}

.addButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    background: #40A353;
    border-radius: 0.5rem;
    padding: 2rem;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    font-size: 1.125rem;
    font-weight: 700;
}

.programsTable {
    width: 75%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-block: 2rem;
}

.programsTable th {
    font-weight: 500;
}

.programsTable th,
.programsTable td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.programsTable th {
    background-color: #f1f1f1;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .dashboardContainer {
        grid-template-columns: 1fr;
    }

    .mainContent {
        margin-top: 60px;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .statsCard h2 {
        font-size: 1.5rem;
    }

    .statsCard p {
        font-size: 0.9rem;
    }

    .programsTable th,
    .programsTable td {
        padding: 8px;
        font-size: 0.8rem;
    }
}