.cardPriceContainer {
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 1rem 1.5rem;
  width: 20rem;
  margin-top: 2rem;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.cardPriceContainer.noButton {
  gap: 1rem;
  padding-bottom: 1rem;
  justify-content: end;
  height: fit-content;
  margin-top: 16rem;
}
.cardPriceContainer h3 {
  color: #667085;
}

.cardPriceContainer h4 {
  margin-bottom: 1rem;
  color: #384250;
}

.cardPriceContainer > h2 {
  color: #111927;
  font-weight: 700;
  font-size: 1.7rem;
  margin-block: 1.5rem;
}

.cardPriceContainer button {
  display: block;
  width: fit-content;
  background: #40a353;
  border: none;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 1.125rem;
  padding-block: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 1.3rem;
}

.cardPriceContainer button:hover {
  background: #40a330;
}

.divInCard {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.75rem;
  color: #667085;
}
.divInCard svg {
  width: 1.5rem;
  height: 1.5rem;
}
.divInCard h2 {
  font-weight: 400;
  font-size: 0.85rem;
  color: #384250;
}
.divInCard p {
  width: fit-content;
  margin-inline-start: auto;
  font-size: 0.85rem;
  font-weight: 500;
  color: #0e1726;
}

.cardPriceContainer p {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardPriceContainer img {
  width: fit-content;
  margin: auto;
}

@media screen and (max-width: 40rem) {
  .cardPriceContainer {
    width: auto;
  }
  .cardPriceContainer.noButton {
    margin-top: auto;
  }
}
