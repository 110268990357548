.subFooter {
  width: 100%;
  text-align: center;
  position: relative;
  padding: 1.2rem 0;
  background: linear-gradient(88.43deg, #8af149 -8.02%, #aad4f7 117.94%);
  color: white;
  bottom: 0;
  flex-shrink: 0;

  gap: 1rem;
}

.inside_footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.lastFooter {
  border-top: 1px solid white;
  padding: 20px;
  color: black;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.SecandDivp1 {
  color: #2589ad;
}

.SecandDivp2 {
  color: black;
}

h4 {
  font-size: 1rem;
  margin: 0;
}

.subFooter h2 {
  font-size: 1.4rem;
  margin: 0;
  color: #2159a6;
  font-weight: bolder;
}

.subFooter h2 a {
  color: black;
  text-decoration: none;
}

.subFooter h2 a:hover {
  text-decoration: underline;
}

.subFooter img {
  height: auto;
}

.content h1 {
  font-size: 25px;
}

.divFlex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.SecandDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
}

.divFlex img {
  margin-right: 20px;
}

.followUs img {
  /* width: %; */
  margin-right: 2px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .subFooter {
    flex-direction: column;
  }

  .content {
    padding: 1rem;
    text-align: center;
  }

  .subFooter h4,
  .subFooter h2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .subFooter h4,
  .subFooter h2 {
    font-size: 0.8rem;
  }
  .inside_footer {
    flex-direction: column;
  }
}
