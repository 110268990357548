.allProgramsContainer {
  margin: 2rem;
}

.allProgramsContainer > h2 {
  color: #2589ad;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-block: 2rem;
}

.contentContainer {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 1.425rem;
  box-shadow: 0px 1.9px 3.8px 0px #00000024;
  width: 75%;
}

.textSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  gap: 0.5rem;
  margin-right: 1.5rem;
  text-align: left;
}

.textSection > img {
  width: 16.5%;
}

.textSection h2 {
  color: #2159a6;
  font-weight: 700;
  font-size: 1.875rem;
  margin-bottom: 1rem;
}

.textSection p {
  margin-top: 1rem;
  margin-bottom: 0.8rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #1e1e1e;
}

.priceSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: #f3f3f3;
  width: fit-content;
  padding: 0.6rem;
  border-radius: 0 7.3px 9.12px 9.12px;
}

.priceBefore {
  opacity: 80%;
  color: #000000;
  font-weight: 500;
  font-size: 9px;
  text-decoration: line-through;
  text-align: center;
}

.priceAfter {
  color: #2159a6;
  font-size: 1rem;
  font-weight: 700;
}

.readMoreBtn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 700;
  font-size: 0.85rem;
  width: fit-content;
  cursor: pointer;
  margin-top: 1rem;
}

.readMoreBtn img {
  margin-right: 0.5rem;
}

.motoContainer {
  width: 35%;
  display: flex;
  align-items: center;
}

.program_image {
  width: 100%;
  border-radius: 20px;
}

.lineThrough {
  text-decoration: line-through;
  color: #b0b0b0;
  margin-right: 0.5rem;
  font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 64rem) {
  .motoContainer img {
    width: 100%;
  }
}

@media (max-width: 55rem) {
  .textSection {
    width: 60%;
  }

  .motoContainer img {
    width: 100%;
  }
}

@media (max-width: 40rem) {
  .content {
    flex-direction: column;
    width: 100%;
  }

  .textSection {
    gap: 0.75rem;
    width: 100%;
  }

  .motoContainer {
    display: none;
  }
}
