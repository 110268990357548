.createProgramContainer {
    height: 200vh;
    margin: 1rem;
}

.backToPrograms {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.backToPrograms img {
    width: 1rem;
}

.backToPrograms h2 {
    font-weight: 700;
    font-size: 1rem;
    color: #1E1E1E;
}

.replaceImgContainer {
    display: flex;
    gap: 1.75rem;
    margin-bottom: 1rem;

}

.replaceImgCard h2 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.75rem;
}

.replaceImg {
    border: 1.45px dashed #2E214A;
    padding: 1.1rem 1.4rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    background: #F8F8FD;
    cursor: pointer;
}

.replaceImg h3 {
    font-weight: 400;
    font-size: 0.725rem;
    color: #515B6F;
}

.replaceImg h3 strong {
    color: #4640DE;
    font-weight: 400;
    font-size: 0.725rem;
}

.replaceImg h4 {
    font-weight: 400;
    font-size: 0.725rem;
    color: #7C8493;
}

.formContainer {
    width: 60%;
}

.inputFields {
    display: flex;
    justify-content: space-between;
}

.inputLabelFields,
.inputLabelFieldsSmall {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-block: 1rem;
}

.inputLabelFields label,
.inputLabelFieldsSmall label {
    font-weight: 500;
    color: #000000;
    font-size: 1rem;
}

.inputLabelFields input,
.inputLabelFieldsSmall input {
    width: 100%;
    padding: 0.55rem 1rem;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #DEE2E6;
    font-size: 1rem;
    color: #000000;
}

.quillContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.quillContainer label {
    font-weight: 500;
    color: #000000;
    font-size: 1rem;
}

.reactQuill {
    width: 100%;
    height: 6rem;
    margin-bottom: 3rem;
}

/* Error Container */
.errorContainer {
    background-color: rgba(255, 0, 0, 0.05);
    border-left: 4px solid #e74c3c;
    padding: 16px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(231, 76, 60, 0.2);
    font-family: 'Arial', sans-serif;
}

/* Field Label */
.errorContainer strong {
    color: #c0392b;
    font-weight: bold;
    display: block;
    font-size: 1rem;
    margin-top: 10px;
}

/* Error Message */
.errorContainer div {
    color: #e74c3c;
    font-size: 0.95rem;
    margin-left: 10px;
    margin-top: 5px;
    line-height: 1.4;
}

/* Error Message Hover */
.errorContainer div:hover {
    color: #c0392b;
}

/* Style for buttons */
.submitButton,
.removeObjective,
.addObjective {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Submit button styling */
.submitButton {
    background-color: #4CAF50;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submitButton:hover {
    background-color: #45a049;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Remove Objective button styling */
.removeObjective {
    background-color: #e74c3c;
    color: #fff;
    padding: 0.3rem 0.6rem;
}

.removeObjective:hover {
    background-color: #c0392b;
}

/* Add Objective button styling */
.addObjective {
    background-color: #3498db;
    color: #fff;
}

.addObjective:hover {
    background-color: #2980b9;
}

/* Style for image input */
.replaceImgCard label {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

.replaceImgCard input[type="file"] {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #333;
    padding: 0.4rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.replaceImgCard input[type="file"]:hover {
    border-color: #bbb;
}

.replaceImgCard input[type="file"]::file-selector-button {
    padding: 0.4rem 0.8rem;
    border: none;
    border-radius: 4px;
    background-color: #3498db;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.replaceImgCard input[type="file"]::file-selector-button:hover {
    background-color: #2980b9;
}