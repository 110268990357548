/* Existing CSS */
.loginContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.formSection {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
}

.highlight {
  color: green;
}

.heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  /* Limit form width for readability */
}

/* .inputField {
  width: 75%;
} */

.loginButton {
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 5px;
  width: 50% !important;
  background-color: black !important;
  color: white !important;
}

.LoginFeild {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.createAccountButton {
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 5px;
  background-color: #40A353 !important;
  color: white !important;
  width: 100%;
}

.forgotPassword {
  color: #555;
  font-size: 0.9rem;
  text-align: right;
  cursor: pointer;
}

.imageSection {
  background-image: url('../../assets/Login_RegisterBackground.svg');
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.backgroundImage {
  width: 100%;
  height: auto;
}

.layerImage {
  width: 60%;
}

.loginNow {
  font-weight: 700;
}

.errorMessage {
  color: red !important;
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 50px !important;
  margin-bottom: 0px !important;

}

.footer strong {
  font-weight: 900;
}

/* Media queries for responsiveness */

@media (min-width: 768px) {
  .loginContainer {
    flex-direction: row;
  }

  .formSection {
    flex: .9;
  }

  .imageSection {
    display: flex;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .formSection {
    padding: 1rem;
  }

  .loginForm {
    width: 90%;
    /* Slightly wider form on mobile */
  }

  .imageSection {
    display: none;
    /* Hide image section on mobile */
  }
}