.dashboardContainer {
  display: flex;
  flex-direction: column; /* Stack items vertically for smaller screens */
  font-family: Arial, sans-serif;
}

.mainContent {
  flex-grow: 1;
  padding: 20px;
}

.cardStatePayment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows items to wrap in smaller screens */
}

.statsCard {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px; /* Limits max width for responsiveness */
  margin-bottom: 15px; /* Adds spacing between cards in narrow views */
}

.statsCard h2 {
  font-size: 2.5rem;
  color: black;
}

.statsCard p {
  font-size: 1.2rem;
  color: #666;
}

.studentTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-x: auto;
}

.studentTable th,
.studentTable td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem; /* Smaller font for better responsiveness */
}

.studentTable th {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #007bff;
  margin-inline: 5px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .dashboardContainer {
    flex-direction: column;
    padding: 10px;
  }

  .statsCard h2 {
    font-size: 1.8rem;
  }

  .statsCard p {
    font-size: 1rem;
  }

  .cardStatePayment {
    flex-direction: column;
    align-items: center;
  }

  .studentTable th,
  .studentTable td {
    padding: 10px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .statsCard h2 {
    font-size: 1.5rem;
  }

  .statsCard p {
    font-size: 0.8rem;
  }

  .studentTable th,
  .studentTable td {
    padding: 8px;
    font-size: 0.7rem;
  }
}
