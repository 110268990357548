.introProgramContainer {
  /* background: url('../../assets/IntroBG.png') no-repeat center center; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
}

.introProgram {

  width: 70%;
  margin: 0;
  padding-bottom: 3rem;
}

.companyLogo {
  margin-block: 2rem;
}

.introProgram h1 {
  color: #f8fc3b;
  font-weight: 700;
  font-size: 4.125rem;
  margin-bottom: 1.4rem;
}

.introProgram h4 {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 40rem) {
  .companyLogo {
    margin-top: auto;
    padding-top: 2rem;
  }

  .introProgram {
    width: auto;
  }
}
