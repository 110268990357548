.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(90deg, #a3e5ff, #a8ff78);
  border-radius: 0 0 20px 0px;
  /* Rounded bottom edges */
  height: 60px;
  /* Adjust height as needed */
}

.homeText {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  margin-left: 15%;
}

.profileSection {
  display: flex;
  align-items: center;
}

.profilePic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.profileName {
  font-size: 0.9rem;
  color: #333;
  font-weight: 600;
}